<template>
  <div>
    <div v-show="exactRoute">
      <v-card>
        <v-card-title>
          Rezervace
          <v-spacer></v-spacer>
          <vc-date-picker
            v-model="dateFilter"
            is-range
            :columns="2"
            color="blue"
            :popover="{ visibility: 'focus' }">
            <template v-slot="{ dateFilter: inputValue, inputEvents }">
              <div class="d-flex justify-center items-center">
                <v-text-field
                  outlined
                  :value="getLocalizedDate(dateFilter.start)"
                  v-on="inputEvents.start"
                  hide-details
                  readonly
                  placeholder="Od"
                  class="rounded-0 border-right-none"
                  dense
                  style="max-width: 120px"
                />
                <v-text-field
                  class="rounded-0 border-left-none"
                  outlined
                  :value="getLocalizedDate(dateFilter.end)"
                  v-on="inputEvents.end"
                  hide-details
                  dense
                  readonly
                  placeholder="Do"
                  style="max-width: 120px"
                />
              </div>
            </template>
          </vc-date-picker>
          <v-form v-model="searchValid" class="d-flex">
            <v-text-field
              v-model.trim="search"
              append-icon="mdi-magnify"
              label="Vyhledat"
              :rules="[validation.required]"
              single-line
              hide-details
              outlined
              ref="searchInput"
              dense
              class="rounded-0"
            ></v-text-field>
            <v-select
              outlined
              class="rounded-0"
              dense
              single-line
              hide-details
              ref="searchTypeInput"
              label="Vyhledat podle"
              :rules="[validation.required]"
              v-model="searchType"
              :items="searchTypes"
            >
            </v-select>
            <v-btn
              color="primary"
              ref="searchButton"
              :disabled="!searchValid || runningRequest"
              @click="filterBySearch"
              class="rounded-0"
            >Vyhledat</v-btn>
            <v-btn
              color="error"
              @click="resetSearch()"
              class="rounded-0"
              ref="searchResetButton"
              :disabled="!searchActive || runningRequest"
            >
              Zrušit vyhledávání
            </v-btn>
          </v-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="reservations"
          class="elevation-1"
          :loading="runningRequest"
          :server-items-length="reservationsTotal"
          :options.sync="paginationOptions"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.userName="{ item }">
              <!-- {{ item.user.firstName }} {{ item.user.lastName }} -->
            {{ item.user.email }}
          </template>
          <template v-slot:item.firm="{ item }">
            <v-chip
              :color="getColor(item)"
              dark
            >
              {{ getFirmText(item) }}
            </v-chip>
          </template>
          <template v-slot:item.propertyName="{ item }">
            <a :href="`/host/properties/${item.property.id}`" class="table-link">
              {{ item.property.name }}
            </a>
          </template>
          <template v-slot:item.dateFrom="{ item }">
            {{ item.dateFrom | localizedDate }}
          </template>
          <template v-slot:item.dateTo="{ item }">
            {{ item.dateTo | localizedDate }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | localizedDate }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              @click="deleteReservation(item)"
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <!-- Start delete dialog -->
      <v-dialog
        width="350"
        v-model="deleteDialog">
        <DeleteResource
          v-if="deleteDialog"
          @delete-success="deleteDemandSuccess"
          @delete-failure="showDeleteFailure"
          resource-type="demands" :resource="reservationToDelete" />
      </v-dialog>
      <!-- End delete dialog -->
    </div>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
import demandsOverviewHelpers from '../../mixins/local/demandsOverviewHelpers'
import { mapGetters } from 'vuex'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'

export default {
  name: 'Reservations',
  components: { DeleteResource },
  mixins: [demandsOverviewHelpers, archiveHelpers],
  data () {
    return {
      search: '',
      searchType: null,
      searchValid: true,
      searchTypes: [],
      searchActive: false,
      deleteDialog: false,
      dateFilter: {
        start: null,
        end: null
      },
      headers: [
        { text: 'Poptávající', align: 'start', value: 'userName' },
        { text: 'Číslo rezervace', value: 'id' },
        { text: 'Objekt', value: 'propertyName' },
        { text: 'Vytvořeno', value: 'createdAt' },
        { text: 'Od', value: 'dateFrom' },
        { text: 'Do', value: 'dateTo' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      runningRequest: false,
      reservations: [],
      reservationsTotal: 0,
      reservationToDelete: {},
      exactRoute: true
    }
  },
  methods: {
    /**
     * Loads data from backend
     * @return void
     */
    getReservations () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      this.$http.get(`/hosts/${this.loggedUser.id}/reservations`, {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search || null,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          from: this.dateFilter.start ? this.dateFilter.start.toISOString().slice(0, 10) : null,
          to: this.dateFilter.end ? this.dateFilter.end.toISOString().slice(0, 10) : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.reservations = res.data.demands
          this.reservationsTotal = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    /**
     * Debounced getReservations
     * @return void
     */
    loadReservations () {},
    /**
     * Redirects to reservation detail
     *
     * @param {Object} reservation
     * @param {Number} reservation.id
     * @return void
     */
    redirectToDetail (reservation) {
      this.$router.push(`/host/reservations/${reservation.id}`)
    },
    /**
     * Sends request with search string
     * @listens searchButton@click
     * @return void
     */
    filterBySearch () {
      this.searchActive = true
      this.getReservations()
    },
    /**
     * Resets search string and gets reservations
     * @listens searchResetButton@click
     * @return void
     */
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.searchActive = false
      this.getReservations()
    },
    /**
     * Shows dialog for deleting reservation
     *
     * @param reservation
     * @return void
     */
    deleteReservation (reservation) {
      this.reservationToDelete = { ...reservation }
      this.deleteDialog = true
    },
    /**
     * Shows success message and fetches updated data
     */
    deleteDemandSuccess () {
      this.showDeleteSuccess()
      this.getReservations()
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'userAuth/user'
    })
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadReservations()
      }
    },
    firmFilter: {
      handler () {
        this.loadReservations()
      }
    },
    dateFilter: {
      deep: true,
      handler () {
        this.loadReservations()
      }
    },
    '$route.params': {
      deep: true,
      handler (newVal, oldVal) {
        // reload pri prechodu z detailu rezervace na prehled
        if (oldVal.id && !newVal.id) {
          this.getReservations()
        }
      }
    }
  },
  mounted () {
    this.getReservations()
    this.loadReservations = this.debounce(this.getReservations, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'

    this.searchTypes = this.headers.filter(header => {
      const searchables = ['userName', 'hostName', 'propertyName', 'dateFrom', 'dateTo']

      return searchables.includes(header.value)
    })
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
